<template>
    <Card class="p-3 text-darkPurple">
        <h2 class="font-bold text-lg mb-5">File Upload and Folder Creation</h2>
    
        <div class="w-full flex flex-col justify-start items-start gap-2 mb-5">
            <p class="font-semibold text-base">Allow employees create folders in ESS:</p>
            <div class="flex ml-2">
              <radio-button
                    :options="createOptionBox"
                    col-span="col-span-6"
                    class=" text-darkPurple"
                    row-gap="gap-x-8"
                    space-between="mr-2"
                    @change="handleCreationChange($event)"
                    v-model="createFoldersModel"
                />
            </div>
        </div>

        <div class="w-full flex flex-col justify-start items-start gap-2 mb-5">
            <p class="font-semibold text-base">Allow employees upload files in ESS:</p>
            <div class="flex ml-2">
                <radio-button
                    :options="uploadOptionBox"
                    col-span="col-span-6"
                    class=" text-darkPurple"
                    row-gap="gap-x-8"
                    space-between="mr-2"
                    @change="handleUploadChange($event)"
                    v-model="uploadFileModel"
                />
            </div>
        </div>

        <div class="line" />
    </Card>
</template>
  
<script>

export default {
    name: "FileUploadFolderCreation",
    components: {
        Card: () => import('@/components/Card'),
        RadioButton: () => import('@/components/RadioButton')
    },
    props: {
        createOptionBox: Array,
        createFolders: Boolean,
        uploadOptionBox: Array,
        uploadFile: Boolean
    },
    data() {
        return {
            createFoldersModel:  this.createFolders,
            uploadFileModel: this.uploadFile
        };
    },

    methods: {
        handleCreationChange(event) {
            this.$emit("creations", JSON.parse(event));
        },
        handleUploadChange(event) {
            this.$emit("upload", JSON.parse(event));
        },
    },
};
</script>

<style scoped>
.line{
  border: 0.3px solid  #878E99;
  width: 100%;
  height: 0;
  margin: auto;
  padding: 0;
}
</style>